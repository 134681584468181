<template>
  <div class="columns">
     <div class="column is-one-third">
        <card-component
          title="1. Run PLS-DA"
          class="tile is-child"
          :displayHelp="$options.name"
        >
          <div class="has-text-centered">
            <b-button rounded
              class="is-primary button-shadow"
              @click.prevent="run()"
            >
              <span class="icon is-small"><i class="fa fa-cogs"></i></span>
              <span> Run </span>
            </b-button>
          </div>
        </card-component>
      </div>
      <div class="column">
        <card-component title="2. Plots" />
        <error :type='"notifications"' />
        <card-no-result v-if="graphicals.length===0">
          <strong>How to set options?<br></strong>
          Now that your datasets are ready to be used, click on the left panel
          to start PLS-DA and wait for the computation (that can be a bit long).
          <br><br>
          <strong>Important note</strong>: PLS-DA is performed with raw values
          (data are not scaled to unit variance before they are processed).
          <strong>If you want to perform PLS-DA on scaled data</strong>
          (predictors only), go to the menu “Edit / Dataset edition” and scale
          your dataset before PLS-DA.
        </card-no-result>
        <card-component v-if="graphicals.length>0">
          <plotly-list
            :data="graphicals"
          ></plotly-list>
        </card-component>
      </div>
  </div>
</template>

<script>
import { ranalysis } from '@/mixins/ranalysis'
import { useWorkspaceStore } from '@/stores/workspacestore'

export default {
  name: 'RPLSDAStep',
  components: {
    'card-component': () => import('@/components/ui/CardComponent.vue'),
    'plotly-list': () => import('@/components/ui/PlotlyList.vue'),
    'card-no-result': () => import('@/components/ui/CardNoResult.vue'),
    'error': () => import('@/components/ui/Error.vue')
  },
  props: {
    dataset: {
      type: String,
      default: null
    }
  },
  mixins: [ranalysis],
  data () {
    return {
      datasetName: null,
      ncp: null,
      seed: ''

    }
  },
  setup () {
    const workspaceStore = useWorkspaceStore()
    return { workspaceStore }
  },
  watch: {
    dataset: function () {
      this.datasetName = this.dataset
    }
  },
  computed: {
    params: function () {
      return {
        'func_name': 'r_plsda',
        'datasetName': this.datasetName,
        'seed': parseInt(this.seed),
        'out_graph': true
      }
    },
    defaultSeed: function () {
      return this.workspaceStore.getSeed
    }
  },
  methods: {
    afterRun: function (data) {
      if (this.hasResults) {
        this.$emit('is-computed', data.ObjectName)
      }
    }
  },
  mounted () {
    if (this.analysisObj === null) {
      this.seed = this.defaultSeed
    }
  }
}
</script>

<style>
  .b-tabs .tab-content {
    padding: 0rem;
  }
  .tabs li.is-active a {
    border-bottom-color: #1896E1 !important;
    color: #1896E1 !important;
  }
</style>
